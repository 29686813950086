.page__wrapper {
  width: 100%;
}
.page__main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 48px;
  background: #f6f7f9;
}
.page__main-container {
  width: 684px;
}
.page__main-conteiner-small {
  display: flex;
  padding: 26px 0;
}
@media (max-width: 1700px) {
  .page__main-container {
    width: 560px;
  }
}
@media (max-width: 1460px) {
  .page__main-container {
    width: 444px;
  }
}
@media (max-width: 1220px) {
  .page__main-container {
    width: 90%;
  }
  .page__main-conteiner-small {
    justify-content: space-between;
  }
  .page__main {
    justify-content: center;
  }
}
@media (max-width: 960px) {
  .page__main {
    padding: 12px 3px;
    width: 100%;
    box-sizing: border-box;
  }
  .page__main-conteiner-small {
    width: 100%;
    flex-wrap: wrap;
  }
}

.landing__additional-list a {
  color: #000;
  /* text-decoration: none; */
  display: block;
  padding: 12px 0;
  font-size: 18px;
}
