.calculator__main {
  width: 1430px;
  margin: 62px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.calculator__main-services {
  padding: 0px 22px;
  box-sizing: border-box;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 20px 60px;
  border-radius: 40px;
  width: 790px;
}

.calculator__data--mobile {
  display: none;
}

.calculator__data-sticky {
  position: sticky;
  top: 62px;
  // z-index: 9999;
}

.calculator__main-pormo-img {
  width: 414px;
  border-radius: 40px;
  margin-top: 8px;
}

.calculator__main-wrapper-scroll {
  position: fixed;
  right: 14%;
  overflow: scroll;
  height: 100%;
  top: 74px;
  background: #ffffff;
  border-radius: 20px;
  z-index: 9999;
}

@media (max-width: 1500px) {
  .calculator__main {
    width: 1300px;
  }
}

@media (max-width: 1462px) {
  .calculator__main-pormo-img {
    width: 392px;
  }
}

@media (max-width: 1380px) {
  .calculator__main {
    width: 95%;
  }
}

.additional-services__modal {
  background: #2696d6;
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 25px 0px 0px 0px;
  text-align: center;
}

.additional-services__input-modal {
  border-radius: 20px;
  background: #fff;
  border: none;
  width: 60%;
  margin: 0 auto;
  padding: 6px;
  margin-top: 8px;
  text-align: center;
}

.additional-services__button-modal {
  padding: 6px 0px;
  width: 60%;
  background: #fff;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  border: none;
  font-family: "Montserrat", sans-serif;
}

//
.calculator__cleaning-titles {
  display: flex;
  justify-content: space-between;
  margin: 22px 0;
}

.calculator-text-wrapper {
  display: flex;
  align-items: center;
  margin: 16px 0;
}

.calculator-text {
  width: 300px;
  display: block;
  font-weight: 300;
  font-size: 22px;
  color: #000000;
}

.calculator-content__label {
  width: 86px;
  text-align: center;
  display: inline-block;
  padding: 8px 16px;
  margin-right: 16px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
}

.calculator-content__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.calculator-text__container {
  position: relative;

  img {
    margin-left: 22px;
    cursor: pointer;
  }
}

.calculator-text__svg {
  position: relative;
}

.calculator-text__svg-modal {
  position: absolute;
  width: 165px;
  top: -29px;
  left: 101px;
  transform: translateX(-50%);
  background-color: white;
  padding: 10px;
  opacity: 0;
  pointer-events: none;
  color: #2696d6;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 99999;
  font-size: 13px;
  text-align: center;
}

.calculator-text__svg:hover .calculator-text__svg-modal {
  opacity: 1;
  pointer-events: auto;
}

.calculator__cleaning-title {
  cursor: pointer;
  width: 32%;
  padding: 19px 12px;
  text-align: center;
  box-sizing: border-box;
  font-size: 20px;
  color: #000000;
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.3s;

  &:hover {
    color: #ffffff;
    background: #2696d6;
  }
}

.calculator__cleaning-title-active {
  color: #ffffff;
  background: #2696d6;
  box-shadow: inset 2px 2px 15px rgba(0, 0, 0, 0.15);
}

.calculator__cleaning-tabs {
  display: flex;
  justify-content: space-between;

  .tab {
    cursor: pointer;
    width: 32%;
    padding: 12px;
    text-align: center;
    box-sizing: border-box;
    font-size: 20px;
    color: #000000;
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: 0.3s;

    &:hover {
      color: #ffffff;
      background: #2696d6;
    }
  }

  .tab__active {
    color: #ffffff;
    background: #2696d6;
    box-shadow: inset 2px 2px 15px rgba(0, 0, 0, 0.15);
  }
}

.calculator__cleaning-content-border {
  border-bottom: 1px solid #2696d6;
}

.calculator__cleaning-content {
  .content {
    display: none;
    padding: 22px 0;
  }

  .content__container {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
  }

  .content__rooms {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 22px;
      width: 50px;
      height: 50px;
      background: #ffffff;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: #ffffff;
        background: #2696d6;
      }
    }

    .content__rooms_active {
      color: #ffffff;
      background: #2696d6;
    }
  }

  .content__active {
    display: block;
  }

  // .content__label {
  //   margin-left: 42px;
  // }

  .content__input {
    width: 200px;
    padding: 12px;
    color: rgba(0, 0, 0, 0.4);
    margin-right: 12px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    border: none;
    border: 1px solid #00000029;
  }
}

@media (max-width: 1462px) {
  .calculator__cleaning-title {
    // width: 211px;
    font-size: 18px;
  }

  .calculator__cleaning-tabs .tab {
    // width: 211px;
    font-size: 18px;
  }
}

@media (max-width: 896px) {
  .calculator__cleaning-title {
    width: 80%;
    font-size: 16px;
    margin: 22px;
  }

  .calculator__cleaning-tabs {
    flex-wrap: wrap;
    justify-content: center;
  }

  .calculator__cleaning-tabs .tab {
    width: 80%;
    font-size: 16px;
    margin: 22px;
  }
}

@media (max-width: 700px) {
  .calculator__cleaning-content .content__container {
    flex-wrap: wrap;
  }

  .content__rooms li {
    margin: 0 22px !important;
  }

  .calculator-text__container {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 530px) {
  .calculator__cleaning-title {
    width: 100% !important;
    margin: 8px 0;
  }

  .calculator__cleaning-tabs .tab {
    width: 100% !important;
    margin: 8px 0 !important;
  }

  .content__rooms li {
    margin: 0 4px !important;
  }

  .calculator-text-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .calculator__cleaning-content .content__label {
    margin: 0;
    width: 90%;
  }

  .calculator__cleaning-content .content__input {
    width: 90%;
    margin: 0;
  }
}

.calendar__services {
  border: 2px solid #2696d6 !important;
}

.rmdp-wrapper {
  width: 442px;
  background: #ffffff !important;
  border-radius: 40px !important;
}

.rmdp-header {
  width: 90%;
  margin: 0 auto;
  padding: 14px 0;
}

.rmdp-day-picker {
  justify-content: center;
}

.rmdp-calendar {
  width: 100%;
}

.rmdp-week {
  width: 360px;
}

.calendar__services .react-calendar__tile--active {
  background: #2696d6;
}

.additional-services__switch {
  display: flex;
}

.additional-services__time {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.additional-services__input {
  width: 48px;
  text-align: center;
  padding: 6px;
  margin: 8px 4px;
  background: #ffffff;
  box-shadow: inset 1px 1px 6px rgb(0 0 0 / 20%);
  border: 1px solid rgba(29, 29, 29, 0.2);
  color: rgba(0, 0, 0, 0.4);
  font-weight: 300;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  line-height: 100%;
  border-radius: 15px;
}

.additional-services__calendar-wrapper {
  margin-top: 62px;
  padding-top: 62px;
  border-top: 1px solid #2696d6;
}

.additional-services {
  padding: 46px 0;
}

.additional-services__title {
  margin-bottom: 26px;
  font-weight: 300;
  font-size: 22px;
  color: #000000;
}

.additional-services__items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  margin: 0;

  .additional-services__item {
    width: 30%;
    height: 150px;
    padding: 12px;
    margin: 18px 0;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;

    .additional-services__item-text-main {
      padding-top: 11px;
      padding-right: 11px;
      text-align: end;
      transition: 0.2s;
    }

    .additional-services__item-text {
      margin-bottom: 6px;
      line-height: 18px;
      position: relative;
      z-index: 999;
      font-weight: 300;
      font-size: 14px;
      color: #000000;
      transition: 0.2s;
    }

    img {
      position: absolute;
      left: -18px;
      top: 32px;
    }

    &:hover {
      background: #2696d6 !important;

      path {
        stroke: #ffffff;
        fill: #ffffff;
      }

      .additional-services__item-text {
        color: #ffffff;
      }

      .additional-services-add-buttons {
        color: #fff;
      }
    }
  }

  .additional-services-add-buttons {
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: flex;
    align-items: center;
  }

  .additional-services-add-button {
    padding: 8px;
    margin: 0 12px;
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 15px;
    border: none;
    cursor: pointer;
  }
}

.additional-services__item-img-info {
  position: relative !important;
  transform: translate(8px, 0px);
}
.additional-services__inputs {
  width: 33%;
}
@media (max-width: 896px) {
  .additional-services__title {
    text-align: center;
  }

  .additional-services__title-mob-off {
    display: none;
  }

  .additional-services__time {
    justify-content: center;
  }

  .additional-services__inputs {
    margin: 22px 0;
    width: 100%;
  }

  .additional-services__items {
    justify-content: center !important;

    .additional-services__item {
      width: 80%;
    }
  }
}

@media (max-width: 530px) {
  .additional-services__items {
    justify-content: center;

    .additional-services__item {
      width: 100%;
    }
  }

  .calculator__main-promo {
    width: 100%;
    display: block;
  }
  .calculator__main-pormo-img {
    width: 318px;
    display: block;
    margin: 0 auto;
  }
}

//  DATA
.calculator__data {
  width: 414px;
  padding: 18px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.06);
  border-radius: 40px;
  font-family: "Montserrat", sans-serif;

  .calculator__data-title {
    margin: 22px 0;
  }

  .calculator__data-input {
    width: 92%;
    padding: 7px 12px;
    margin: 4px 0;
    background: #ffffff;
    box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.2);
    border: 1px solid #1d1d1d33;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 300;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    line-height: 100%;
    border-radius: 15px;
  }

  .calculator__data-button {
    display: flex;
    // width: 100%;
    padding: 8px 12px;
    margin: 12px 0;
    justify-content: center;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.9;
    }
  }

  .calculator__data-button-disabled {
    background: #d3d3d3;
    cursor: default;
  }

  .calculator__data-text {
    font-weight: 300;
    font-size: 13px;
    line-height: 100%;
  }

  .calculator__data-agreement {
    text-decoration: none;
    color: #2696d6;
    margin: 0 4px;
  }

  .calculator__data-textarea {
    width: 100%;
    height: 72px;
    padding: 12px;
    // margin-top: 42px;
    // margin-bottom: 12px;
    background: #ffffff;
    box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.2);
    border: 1px solid #1d1d1d33;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 300;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    line-height: 100%;
    border-radius: 15px;
    resize: none;
  }

  .calculator__data-input-promo {
    margin: 18px 0;
    position: relative;

    .calculator__data-input-promo-button-bg {
      background: rgb(255, 29, 29) !important;
    }

    .calculator__data-input-promo-button {
      padding: 10px;
      width: 142px;
      box-sizing: border-box;
      background: #2696d6;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
      border-radius: 15px;
      color: #ffffff;
      font-size: 16px;
      line-height: 100%;
      border: none;
      position: absolute;
      top: 4px;
      right: -3px;
      font-family: "Montserrat", sans-serif;
      cursor: pointer;
      text-align: center;
    }
  }

  .calculator__data-total {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #5e54c7;
    padding: 18px 0 18px 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
  }

  .calculator__data-pay-items {
    display: flex;
    padding: 18px 0 0 0;
  }

  .calculator__data-pay-delete {
    font-size: 18px;
    font-weight: 500;
    padding: 6px 0px;
    display: inline-flex;
    width: auto;
    cursor: pointer;
  }

  .calculator__data-pay-item {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    margin-right: 14px;
    text-align: center;
    background: #ffffff;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transform: scale(1.03);
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.214);
    }
  }

  .calculator__data-pay-item-active {
    transform: scale(1.15) !important;
    box-shadow: 1px 1px 6px rgba(33, 33, 33, 0.317) !important;
  }
}

.calculator__data-list {
  padding: 0;
  list-style: none;

  li {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    line-height: 100%;
    padding: 9px 0;
  }
}

@media (max-width: 1462px) {
  .calculator__data {
    width: 392px;
  }

  .calculator__data-input-promo {
    .calculator__data-input-promo-button {
      right: -10px !important;
    }
  }
}

@media (max-width: 1245px) {
  .calculator__main {
    flex-wrap: wrap;
    justify-content: center;
  }
  .calculator__data {
    margin: 22px auto;
    width: 700px;
  }
  .calculator__main-services {
    width: 100%;
  }
}

@media (max-width: 760px) {
  .calculator__data {
    margin: 22px auto;
    width: 100%;
  }
}

@media (max-width: 560px) {
  .calculator__data {
    width: 100%;
  }

  .calculator__data-input-promo {
    .calculator__data-input-promo-button {
      padding: 18px !important;
      width: 180px !important;
      top: 4px !important;
    }
  }

  .calculator__data
    .calculator__data-input-promo
    .calculator__data-input-promo-button {
    width: 130px !important;
    font-size: 16px;
    top: 3px !important;
    right: -3px !important;
    padding: 11px !important;
  }
}

//
.calendar__services {
  border: 2px solid #2696d6 !important;
}

.calendar__services .react-calendar__tile--active {
  background: #2696d6;
}

.additional-services__switch {
  display: flex;
}

@media (max-width: 530px) {
  .additional-services__switch {
    justify-content: center;
  }
}

.additional-services__time {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.additional-services__input {
  width: 48px;
  text-align: center;
  padding: 6px;
  margin: 8px 4px;
  background: #ffffff;
  box-shadow: inset 1px 1px 6px rgb(0 0 0 / 20%);
  border: 1px solid rgba(29, 29, 29, 0.2);
  color: rgba(0, 0, 0, 0.4);
  font-weight: 300;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  line-height: 100%;
  border-radius: 15px;
}

.additional-services__calendar-wrapper {
  margin-top: 62px;
  padding-top: 62px;
  border-top: 1px solid #2696d6;
}

.additional-services {
  padding: 46px 0;
}

.additional-services__title {
  margin-bottom: 26px;
  font-weight: 300;
  font-size: 22px;
  color: #000000;
}

.additional-services__items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  margin: 0;

  .additional-services__item {
    width: 32%;
    height: 132px;
    padding: 12px;
    margin: 18px 0;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;

    .additional-services__item-text-main {
      padding-top: 0px;
      padding-right: 11px;
      text-align: end;
      transition: 0.2s;
    }

    .additional-services__item-text {
      margin-bottom: 6px;
      line-height: 18px;
      position: relative;
      z-index: 999;
      font-weight: 300;
      font-size: 14px;
      color: #000000;
      transition: 0.2s;
    }

    img {
      position: absolute;
      left: -19px;
      top: 26px;
      width: 110px;
    }

    &:hover {
      background: #2696d6 !important;

      path {
        stroke: #ffffff;
        fill: #ffffff;
      }

      .additional-services__item-text {
        color: #ffffff;
      }

      .additional-services-add-buttons {
        color: #fff;
      }
    }
  }

  .additional-services-add-buttons {
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: flex;
    align-items: center;
  }

  .additional-services-add-button {
    padding: 8px;
    margin: 0 12px;
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 15px;
    border: none;
    cursor: pointer;
  }
}

.additional-services__item-img-info {
  position: relative !important;
  transform: translate(8px, 0px);
}

@media (max-width: 896px) {
  .additional-services__title {
    text-align: center;
  }

  .additional-services__time {
    justify-content: center;
  }

  .additional-services__inputs {
    margin: 22px 0;
  }

  .additional-services__items {
    justify-content: center !important;

    .additional-services__item {
      width: 80%;
    }
  }
}

.additional-services__title__mobile-icon {
  display: none;
}

.additional-services__title__mobile {
  display: none;
}

.additional-services__title__mobile-open {
  display: none;
}

@media (max-width: 700px) {
  .additional-services__items {
    justify-content: center;

    .additional-services__item {
      width: 100%;
    }
  }
  .additional-services__title__mobile {
    width: 100%;
    background: #2696d6;
    color: #ffffff;
    border-radius: 50px;
    text-align: center;
    padding: 16px 0px;
    font-size: 18px;
    display: block;
    margin: 0 auto;
    position: relative;
    display: block;
  }
  .additional-services__title__mobile-open {
    width: 100%;
    background: #ffffff;
    color: #000000;
    border-radius: 50px;
    text-align: center;
    padding: 16px 0px;
    font-size: 18px;
    display: block;
    margin: 0 auto;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  .additional-services__mobile {
    display: none;
  }
  .additional-services__mobile-open {
    display: block;
  }
  .additional-services__title__mobile-icon {
    position: absolute;
    right: 10%;
    animation: blink 1.5s infinite;
    top: 20px;
    display: block;
  }
}

//

.tabs__furniture {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tab__furniture {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 7px;
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  font-size: 20px;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.tab__furniture-active {
  background: #f8a97f;
  box-shadow: inset 2px 2px 15px rgba(0, 0, 0, 0.15);
  color: #ffffff;
}

.react-calendar {
  width: 500px;
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #f8a97f;
  border-radius: 40px;
  padding: 14px;
}

.react-calendar__navigation__label {
  border: none;
  text-align: center;
  background: none;
  font-size: 22px;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
}

.react-calendar__navigation {
  text-align: center;
  display: flex;
  align-items: center;
}

.react-calendar__navigation__prev-button {
  border: none;
  background: none;
  font-size: 32px;
}

.react-calendar__navigation__next-button {
  border: none;
  background: none;
  font-size: 32px;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;

  abbr {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 100%;
    text-decoration: none;
    text-align: center;
  }
}

.react-calendar__month-view__days__day {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 10%);
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: none;
  margin: 8px 8px;
  flex: none !important;
  font-size: 20px;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__tile--active {
  color: #ffffff;
  background: #f8a97f;
  box-shadow: inset 2px 2px 15px rgba(0, 0, 0, 0.15);
}

.calculator-data__mobile {
  display: none;
}

@media (max-width: 500px) {
  .calculator__data--mobile {
    display: block;
    position: fixed;
    background: #fff;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 99999;
    padding: 14px 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    box-shadow: rgb(52 52 52 / 29%) 0px 20px 60px 0px;

    div {
      text-align: center;
      font-size: 18px;
    }
  }

  .tab__furniture {
    width: 100%;
    margin-bottom: 16px;
  }

  .react-calendar {
    width: 330px;
    margin: 0 auto;
  }

  .react-calendar__month-view__days {
    justify-content: space-between;
  }

  .react-calendar__month-view__days__day {
    width: 35px;
    height: 35px;
    font-size: 14px;
    margin: 8px 2px;
  }

  .rmdp-wrapper {
    width: 320px;
  }

  .rmdp-week {
    width: 260px;
  }

  .calculator__data-sticky {
    z-index: 99999;
  }

  .calculator-data__mobile {
    display: block;
    width: 80%;
    position: fixed;
    bottom: 0;
    background: #2696d6;
    color: #ffffff;
    z-index: 9999999;
    // border-top-right-radius: 20px;
    // border-top-left-radius: 20px;
    border-radius: 50px;
    right: 50%;
    transform: translate(-50%, -10px);
    left: 50%;
    box-shadow: 0px 10px 40px rgb(0 0 0 / 31%);

    div {
      text-align: center;
      padding: 16px 0px;
      font-size: 18px;
      position: relative;
    }

    .calculator-data__mobile-arrow {
      position: absolute;
      top: 22px;
      left: 60%;
    }
  }

  .calculator__data .calculator__data-title {
    margin: 22px 0;
    text-align: center;
  }

  form {
    // width: 93% !important;
    margin: 0 auto;
  }

  .calculator__data .calculator__data-text {
    width: 90%;
    margin: 0 auto;
  }

  .calculator__data .calculator__data-input {
    // width: 100%;
  }

  .calculator__data-input-promo .calculator__data-input-promo-button {
    right: 0px !important;
    width: 119px !important;
    font-size: 12px;
  }
}

@media (max-width: 520px) {
  .calculator-text__svg-modal {
    left: 51px;
    top: -41px;
  }
}

@media (max-width: 355px) {
  .react-calendar {
    width: 314px;
    padding: 8px !important;
  }

  .react-calendar__month-view__days__day {
    width: 37px;
    height: 37px;
    margin: 4px 0px;
  }

  .calculator-text__svg-modal {
    left: 21px;
    top: -41px;
  }
}

@media (max-width: 332px) {
  .react-calendar {
    width: 296px;
  }
}
