.questions__order-tabs {
  display: flex;
}
.questions__order-tab {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 22px;
  transition: 0.3s;
  width: 221px;
  text-align: center;
  &:hover {
    background-color: #e0e0e0;
  }
}

.questions__order-tab-active {
  background-color: #e0e0e0;
}

.questions__order-tab-content {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}

.questions__order-tab-content-text {
  // margin-left: 62px;
  // width: 452px;
  width: 50%;

  .questions__order-accrodion-container {
    width: 100%;
  }
}

.questions__order-tab-content-accrodion-title {
  width: 100%;
  padding: 22px 0;
  border-bottom: 1px solid #e4e4e4;
  font-size: 22px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.questions__order-accrodion-arrow {
  transition: 0.3s;
  cursor: pointer;
}
.questions__order-accrodion-arrow-active {
  transform: rotate(180deg);
  transition: 0.3s;
}

.questions__order-accrodion-wrapper {
  background: #fff;
  padding: 18px 8px;
  border-radius: 8px;
  margin-top: -8px;
  div {
    padding: 16px 0;
    font-weight: 300;
    font-size: 15px;
  }
}

.questions__order-accrodion-wrapper-text {
  border-bottom: 1px solid #e4e4e4c0;
  span {
    font-weight: 500;
    color: #2696d6;
    margin-right: 4px;
  }
}

.questions__order-tab-text {
  margin-left: 62px;
  div {
    border-bottom: 1px solid #e4e4e4;
    padding: 16px 0;
    width: 280px;
  }
}

.questions__order-tab-img {
  // width: 492px;
  border-radius: 12px;
  margin-bottom: 22px;
  width: 100%;
}

.questions__order-tab-imgs {
  // width: 492px;
  width: 45%;
}

.questions__order-button {
  padding: 8px 16px;
  display: flex;
  margin: 26px auto;
  text-decoration: none;
  color: #000;
  background: #ffffff;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: 0.3s;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  width: 100%;
  justify-content: center;
  &:hover {
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
  }
}

@media (max-width: 1100px) {
  .questions__order-tab-content {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .questions__order-tab-content-text {
    width: 100%;
    margin: 0 0 33px 0;
  }

  .questions__order-tab-imgs {
    width: 100%;
  }
  .questions__order-tab-img {
    width: 100%;
  }
}

@media (max-width: 715px) {
  .questions__order-tab-content {
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .questions__order-tabs {
    padding-left: 24px;
    padding-right: 24px;
    overflow-x: scroll;
    white-space: nowrap;
  }

  .questions__order-tab {
    font-size: 24px;
    padding: 12px;
    display: flex;
  }

  .questions__order-tab-text {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .questions__order-tab-imgs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
