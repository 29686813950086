.calculator-stock {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.calculator__main {
  justify-content: space-between;
}
.tabs__furniture {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    margin-bottom: 18px;
  }
}

.tab__furniture {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 44px 7px;
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  font-size: 20px;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  position: relative;

  img {
    position: absolute;
    // right: 10px;
    left: 16px;
    top: 25px;
  }
  .tab__furniture-text {
    div:first-child {
      margin-bottom: 6px;
    }
  }
  .additional-services-add-buttons {
    right: 6px;
    bottom: 50px;
  }
}

.additional-services-add-buttons {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: center;
}

.additional-services-add-button {
  padding: 8px;
  margin: 0 12px;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  transition: 0.1s ease all;
  // &:hover {
  //   background: #f8a97f;
  //   color: #ffffff;
  // }
}

.tab__furniture-active {
  background: #f8a97f;
  box-shadow: inset 2px 2px 15px rgba(0, 0, 0, 0.15);
  color: #ffffff;
}

.react-calendar {
  width: 500px;
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #f8a97f;
  border-radius: 40px;
  padding: 14px;
}

.react-calendar__navigation__label {
  border: none;
  text-align: center;
  background: none;
  font-size: 22px;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
}

.react-calendar__navigation {
  text-align: center;
  display: flex;
  align-items: center;
}

.react-calendar__navigation__prev-button {
  border: none;
  background: none;
  font-size: 32px;
}

.react-calendar__navigation__next-button {
  border: none;
  background: none;
  font-size: 32px;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;

  abbr {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 100%;
    text-decoration: none;
    text-align: center;
  }
}

.react-calendar__month-view__days__day {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 10%);
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: none;
  margin: 8px 8px;
  flex: none !important;
  font-size: 20px;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__tile--active {
  color: #ffffff;
  background: #f8a97f;
  box-shadow: inset 2px 2px 15px rgba(0, 0, 0, 0.15);
}

@media (max-width: 500px) {
  .tab__furniture {
    width: 100%;
    margin-bottom: 16px;
  }

  .react-calendar {
    width: 330px;
    margin: 0 auto;
  }

  .react-calendar__month-view__days {
    justify-content: space-between;
  }

  .react-calendar__month-view__days__day {
    width: 35px;
    height: 35px;
    font-size: 14px;
    margin: 8px 2px;
  }
}

@media (max-width: 355px) {
  .react-calendar {
    width: 314px;
    padding: 8px !important;
  }

  .react-calendar__month-view__days__day {
    width: 37px;
    height: 37px;
    margin: 4px 0px;
  }
}

@media (max-width: 332px) {
  .react-calendar {
    width: 296px;
  }
}
