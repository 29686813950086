.registration-form {
  max-width: 977px;
  margin: 0 auto;
  position: relative;
}

.registration-title {
  text-align: center;
  margin: 62px 0;
  font-size: 28px;
}

.form {
  margin-top: 52px;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.input-group {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
}

.input-group label {
  margin-bottom: 5px;
  margin-left: 9px;
  font-weight: 400;
  font-size: 13px;
}

.input-group input {
  padding: 21px 10px;
  border: 1px solid #a1adcc;
  border-radius: 20px;
  box-sizing: border-box;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.checkbox-group label {
  display: flex;
  align-items: center;
}

.from-goup-container {
  margin-top: 46px;
  margin-bottom: 28px;
}

.from-goup-button {
  padding: 20px 85px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  display: block;
  margin: 62px auto;
}

.from-goup-button:hover {
  background-color: #2f94ff;
}

.step-indicator {
  display: flex;
  align-items: baseline;
  //   align-items: center;
  gap: 10px;
  //   width: 90%;
  margin: 38px auto;
}

.step {
  display: flex;
  justify-content: space-between;
  //   width: 152px;
  flex-direction: column;
  align-items: center;
  color: #b0b0b0;
}

.step.active {
  color: #1a73e8; /* Цвет активных шагов */
}

.circle {
  width: 40px;
  height: 40px;
  border: 2px solid #b0b0b0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  //   margin-right: 16px;
}

.step.active .circle {
  border-color: #1a73e8;
  color: #1a73e8;
}

.line {
  flex-grow: 1;
  height: 2px;
  background-color: #b0b0b0;
}

.step.active + .line {
  background-color: #1a73e8;
}

.check {
  font-size: 18px;
}

.input-group {
  position: relative;
}

.check-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  color: green;
  font-size: 16px;
}

.registration-form {
  position: relative;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 632px;
  width: 100%;
  box-sizing: border-box;
}

.modal-content h2 {
  color: #ff00ff; /* Цвет текста заголовка */
  margin-bottom: 10px;
}

.modal-content p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.close-button {
  padding: 21px 80px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.close-button:hover {
  background-color: #0374ec;
}

.form-info-text {
  margin: 43px 0;
}

.form-info-text-intro {
  color: #004fea;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  display: flex;
  img {
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .registration-form {
    width: 90%;
  }
  .input-group {
    flex: 1 1 100%;
  }
  .form-info-text {
    font-size: 13px;
  }
  .circle {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .step {
    font-size: 13px;
  }
  .modal-content {
    padding: 10px;
    width: 95%;
  }
}
