.slick-dots li button:before {
  color: #2696d6; /* Изменить цвет точек */
}
.slick-prev:before,
.slick-next:before {
  color: #2696d6; /* Цвет стрелок навигации */
}

.slick-dots {
  top: 48px;
  height: 33px;
}
.slick-dots li button:before {
  font-size: 11px;
}

.slick-dots li {
  width: 30px !important;
  height: 30px !important;
}

.slick-dots li button {
  width: 30px !important;
  height: 30px !important;
}

.tab__content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin: 6px 0;
}

.tabs__items {
  display: flex;
  padding: 0;
  margin: 92px 0 0 112px;
  list-style: none;

  .tab__item {
    padding: 12px 26px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 100%;
    cursor: pointer;

    &:hover {
      background: #f2f4f7;
    }
  }

  .tab__item-active {
    background: #f2f4f7;
    font-weight: 500;
  }
}

.tab__content {
  padding-top: 42px;
  background: #f2f4f7;
}

.content {
  display: none;
}

.tab__content-active {
  display: block;
  width: 94%;
  box-shadow: 1px 1px 4px #00000047;
  margin-left: auto;
  border-top-left-radius: 20px;
  padding: 32px;
  box-sizing: border-box;
  background: #ffffff;
}

@media (max-width: 1400px) {
  .tabs__items {
    margin: 92px 0 0 34px;

    .tab__item {
      padding: 12px 16px;
      font-size: 18px;
    }
  }
}

@media (max-width: 1040px) {
  .tabs__items {
    margin: 92px 0 0 0px;
    border-radius: 20px;

    .tab__item {
      padding: 12px 12px;
      font-size: 16px;
    }
  }

  .tab__content {
    padding: 0;
  }

  .tab__content-active {
    width: 100%;
    padding: 0;
    box-shadow: none;
    border-top-left-radius: inherit;
  }
}

@media (max-width: 790px) {
  .tabs__items {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 32px;

    .tab__item {
      border-radius: 18px;
    }
  }
}

@media (max-width: 530px) {
  .tab__content {
    background: #ffffff;
  }
  .tab__content-active {
    width: 100%;
    padding: 11px;
    box-shadow: none;
    border-top-left-radius: inherit;
  }
  .completed-services__items {
    margin-top: 58px;
  }
  .completed-services__item {
    width: 90%;
    margin: 0 auto;
  }
  .links-calculator li {
    width: 95%;
    margin: 0 auto;
  }
  // .completed-services__item-header {
  //   justify-content: center;
  // }
}
