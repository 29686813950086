.landing__container {
  width: 1430px;
  margin: 0 auto;
}

.landing__container-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.landing__bg {
  width: 100%;
}

.landing__promo {
  width: 100%;
  // padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  // background: #fff;
  // box-shadow: 0px 25px 70px rgba(0, 0, 0, 0.08);
  // backdrop-filter: blur(15px);
  // border-radius: 20px;
  margin-top: 22px;
  // transform: translateY(246px);
  // position: absolute;
  // left: 0;

  .title {
    font-size: 14px;
    color: #000000;
  }
  .promo {
    padding: 8px;
    width: 123px;
    text-align: center;
    background: #fac91e;
    border-radius: 15px;
    box-sizing: border-box;
    cursor: pointer;
    color: #ffffff;

    &:hover {
      background: #f1be02;
    }
  }
}

.landing__form-border {
  border-top: 1px solid #00000017;
  margin: 28px 0;
  border-bottom: 1px solid #00000017;
  padding-bottom: 28px;
}

.landing__additional-title {
  font-size: 32px;
  font-weight: 800;
  text-align: center;
  padding: 18px 0;
}

.landing__wrapper-form {
  width: 100vw;
  height: 100%;
  /* background: url('../../../assets/bg1.jpg'); */
  background: url("../../../assets/img-landing/head-bg.svg");
  padding: 86px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.landing__main-form {
  display: flex;
  justify-content: flex-end;
}

.landing__main-form-submit-modal {
  position: fixed;
  background: #00000057;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.close {
  width: 100%;
  padding: 8px 16px;
  display: flex;
  margin: 16px auto 0 auto;
  background: #ffffff;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: 0.3s;
  cursor: pointer;
  box-sizing: border-box;
  justify-content: center;
}

.landing__main-form-submit {
  width: 490px;
  padding: 24px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 25px 70px rgba(0, 0, 0, 0.08);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: 20px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  right: 50%;
  top: 50%;
  font-size: 18px;
  z-index: 99;
  position: fixed;

  .title {
    text-align: center;
  }

  .text {
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
  }
}

.landing__form {
  width: 390px;
  padding: 24px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 25px 70px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(15px);
  border-radius: 20px;
}

.landing__form-title {
  font-size: 25px;
  line-height: 130%;
  letter-spacing: -0.03em;
  font-family: "Montserrat", sans-serif;
  color: #000000;
}

.landing__form-button {
  width: 100%;
  border: none;
  letter-spacing: -0.03em;
  display: flex;
  padding: 10px 0;
  margin-top: 22px;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  background: #fac91e;
  border-radius: 15px;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.landing__form-placeholder {
  border: none;
  outline: none;
  padding: 12px 0 12px 6px;
  width: 100%;
  margin: 12px 0;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  letter-spacing: -0.03em;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0);
}

.landing__tab {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: -67px auto 0 auto;

  li {
    width: 337px;
    height: 143px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 22px 18px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s;
  }

  .landing__tab-text-hover {
    opacity: 0;
    transition: 0.3s;
  }

  li:hover .landing__tab-text-hover {
    opacity: 1;
  }

  .landing__tab-title {
    margin-bottom: 12px;
    letter-spacing: -0.03em;
    font-size: 30px;
    line-height: 100%;
    font-family: "Montserrat", sans-serif;
  }

  .landing__tab-text {
    font-size: 16px;
    line-height: 100%;
    color: #2696d6;
  }
}

.slider__tab-mobile {
  display: none;
}

.landing__tab-active {
  background: #ffffff !important;

  .landing__tab-title {
    color: #2696d6;
  }

  .landing__tab-text {
    color: #000000;
  }
}

.regulations {
  display: flex;
  justify-content: space-between;
  padding: 74px 0;
  margin: 32px auto;
  align-items: center;
}

.regulations__title {
  color: #272727;
  font-size: 45px;
  line-height: 55px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
}

.regulations__text {
  margin-bottom: 14px;
  font-weight: 800;
  font-size: 25px;
  line-height: 120%;
  color: #272727;
  font-family: "Montserrat", sans-serif;
}

.regulations__items {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  li {
    width: 212px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .regulations__item-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: #2696d6;
    border-radius: 60px;
  }

  .regulations__item-text {
    padding-left: 18px;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    line-height: 120%;
    color: #272727;
  }
}

.arrow-1 {
  position: absolute;
  right: 10px;
}

.arrow-1 div {
  position: relative;
  top: 20px;
  width: 103px;
  height: 2px;
  background-color: #000000;
  box-shadow: 0 3px 5px rgb(0 0 0 / 20%);
  left: -22px;
  display: block;
  border-radius: 10px;
}

.arrow-1 div::after {
  content: "";
  position: absolute;
  width: 17px;
  height: 2px;
  top: -5px;
  right: -3px;
  background-color: #000000;
  transform: rotate(45deg);
  border-radius: 10px;
}

.arrow-1 div::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 2px;
  top: 6px;
  right: -3px;
  background-color: #000000;
  box-shadow: 0 3px 5px rgb(0 0 0 / 20%);
  transform: rotate(-45deg);
  border-radius: 10px;
}

.benefits__wrapper {
  padding: 72px 0;
  background: #f4f4f4;
}

.benefits {
  display: flex;
  justify-content: space-between;
}

.benefits__more {
  width: 415px;
  height: 270px;
  box-shadow: 0px 20px 60px rgb(0 0 0 / 10%);
  border-radius: 30px;
  box-sizing: border-box;
  padding: 18px;
  background: #ffffff;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0px;
    background: url("../../../assets/corporate-education-or-business-skills-improvement-idea.svg");
  }
}

.benefits__more-title {
  position: relative;
  z-index: 99;
  font-weight: 800;
  font-size: 25px;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
}

.benefits__more-button {
  display: flex;
  width: 90%;
  padding: 8px;
  justify-content: center;
  color: #ffffff;
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  font-family: "Montserrat", sans-serif;
  background: #fac91e;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  text-decoration: none;
  box-sizing: border-box;
  z-index: 999;
  position: absolute;
  left: auto;
  bottom: 20px;
  transition: 0.3s;

  &:hover {
    background: #f1be02;
  }
}

.benefits__title {
  margin-bottom: 43px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 32px;
  line-height: 100%;
}

.benefits__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
  // width: 64%;
  width: 100%;

  li {
    width: 452px;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    margin-bottom: 28px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    transition: 0.3s;

    &:hover {
      transform: scale(1.01);
      box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.159);
    }
  }

  .benefits__item-text-container {
    width: 80%;

    div:first-child {
      font-family: "Montserrat", sans-serif;
      font-size: 19px;
      line-height: 120%;
      font-weight: 800;
    }
  }

  .benefits__item-text {
    color: #000000;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 120%;
  }

  .benefits__item-img {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eeeeee;
    border-radius: 60px;
  }
}

.calculate__the-cost {
  display: flex;
  width: 415px;
  padding: 8px 12px;
  margin-bottom: 42px;
  justify-content: center;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  text-decoration: none;
  background: #2696d6;
  box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  transition: 0.3s;
  font-weight: 800;

  &:hover {
    background: #0587d3;
  }
}

.questions__main-title {
  color: #2696d6;
  font-weight: 800;
  font-size: 23px;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  position: relative;
  margin: 33px 0;
  width: 400px;

  &::before {
    content: "";
    position: absolute;
    width: 35px;
    height: 35px;
    right: 0;
    top: -5px;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  }

  &::after {
    content: "";
    position: absolute;
    background: url("../../../assets/arrow-landing.svg");
    width: 25px;
    height: 25px;
    right: 1px;
    top: 9px;
    background-repeat: no-repeat;
  }
}

.questions__title-wrapper {
  margin: 22px 0;
}

.questions__title {
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  font-family: "Montserrat", sans-serif;
}

.questions__text {
  font-size: 15px;
  line-height: 120%;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 1500px) {
  .landing__container {
    width: 1300px;
  }

  .landing__tab li {
    width: 302px;
  }

  .regulations__title {
    font-size: 36px;
    line-height: 49px;
  }

  .benefits__items {
    // width: 66%;

    li {
      width: 408px;
    }
  }

  .questions__title {
    font-size: 21px;
  }
}

@media (max-width: 1380px) {
  .landing__container {
    width: 95%;
  }

  .landing__wrapper-form {
    padding: 62px 0;
  }

  .landing__main-form-tab {
    margin-bottom: 52px;
    background: #ffffff;
    border-radius: 20px;
  }

  .landing__tab li {
    width: 272px;
    height: 132px;
  }

  .regulations__title {
    font-size: 26px;
    line-height: 33px;
  }

  .benefits__items li {
    width: 366px;
  }

  .benefits__items .benefits__item-text-container div:first-child {
    font-size: 18px;
    line-height: 112%;
    margin-bottom: 3px;
  }

  .benefits__items .benefits__item-text {
    font-size: 14px;
    line-height: 100%;
  }

  .calculate__the-cost {
    width: 383px;
  }

  .benefits__more {
    width: 383px;
  }

  .benefits__more-title {
    font-size: 23px;
  }
}

@media (max-width: 1230px) {
  .landing__tab li {
    width: 256px;
    height: 125px;
  }

  .regulations__title {
    font-size: 21px;
  }

  .calculate__the-cost {
    width: 354px;
  }

  .benefits__more {
    width: 354px;
  }

  .benefits__more-title {
    font-size: 21px;
  }

  .benefits__items li {
    width: 352px;
  }

  .questions__title {
    font-size: 18px;
  }
}

@media (max-width: 1130px) {
  .landing__tab li {
    width: 24%;
  }

  .regulations {
    flex-wrap: wrap;
    justify-content: center;
  }

  .regulations__title {
    font-size: 32px;
    line-height: 120%;
  }

  .benefits {
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
  }

  .benefits__items {
    width: 100%;
  }

  .benefits__items li {
    width: 48%;
  }

  .benefits__more {
    width: 414px;
  }

  .calculate__the-cost {
    width: 414px;
  }

  .questions__title {
    margin: 62px 0;
    text-align: center;
  }
}

@media (max-width: 912px) {
  .landing__promo {
    padding: 24px 0;
    left: 0;
    transform: translateY(0);
    position: relative;
    margin-bottom: 32px;
  }
  .landing__container-tab {
    flex-wrap: wrap;
    justify-content: center;
  }

  .regulations__items li {
    width: 192px;
  }

  .landing__tab li {
    width: 24%;
    height: 104px;
  }

  .landing__tab .landing__tab-title {
    font-size: 22px;
  }

  .landing__main-form-submit {
    width: 90%;
  }
}

@media (max-width: 780px) {
  .landing__tab {
    display: none;
  }

  .slider__tab-mobile {
    display: block;
    margin: -67px auto 0 auto !important;
  }

  .landing__tab li {
    width: 95% !important;
    margin: 14px auto !important;
  }

  .landing__tab {
    margin: -144px auto 0 auto;
  }

  .slick-list {
    padding: 0px 0px !important;
  }

  .regulations__text {
    text-align: center;
  }

  .regulations__items {
    flex-wrap: wrap;
    justify-content: center;

    li {
      width: 100%;
      justify-content: center;
      margin: 18px 0;
    }
  }

  .arrow-1 {
    display: none;
  }
}

@media (max-width: 620px) {
  .benefits__items {
    li {
      width: 100%;
    }
  }
}

@media (max-width: 446px) {
  .landing__promo {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    .promo {
      width: 100%;
      margin-bottom: 8px;
    }
  }
  .landing__wrapper-form {
    padding: 50px 0 120px 0;
  }

  .landing__form {
    min-width: 310px;
    width: 100%;
    padding: 16px;
  }

  .landing__form-title {
    font-size: 21px;
  }

  .landing__form-button {
    font-size: 18px;
  }

  .landing__main-form {
    justify-content: center;
    width: 100%;
  }

  .landing__form-placeholder {
    background: #00ffff00;
  }

  .calculate__the-cost {
    width: 100%;
    margin-top: 42px;
  }

  .benefits__title {
    text-align: center;
  }

  .benefits__more {
    width: 100%;
  }

  .questions__main-title {
    font-size: 18px;
    margin: 53px auto;
    width: 348px;
  }
}
