.auth {
  width: 100vw;
  height: 100vh;
  background: url("../../../assets/auth/wrapper.svg") no-repeat center center;
}

.auth__wrapper {
  margin: 0 auto;
  padding: 90px 0;
  width: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.auth__form-container {
  text-align: center;
}

.auth__form-button {
  background: #2696d6;
  border: none;
  border-radius: 16px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 22px;
  justify-content: center;
  line-height: 100%;
  margin: 22px auto;
  padding: 8px 16px;
  width: 312px;
}
.disabled {
  background: #dfdfdf;
}
.auth__form-input {
  background: #d4eaf6;
  border: 1px solid #dfdfdf;
  border-radius: 16px;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  margin: 22px auto;
  padding: 12px 16px;
  width: 312px;
  border: none;
  box-sizing: border-box;
}

.auth__form-button-yandex {
  align-items: center;
  background-color: #000;
  color: #fff;
  display: flex;
  font-size: 20px;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .auth__wrapper {
    width: 100%;
  }
  .auth {
    width: 100%;
    background: url("../../../assets/auth/wrapper-mob.svg") no-repeat center
      center;
    background-size: cover;
  }
  .auth__logo {
    width: 80%;
  }
}
