.about-container {
  max-width: 800px;
  margin: 80px auto;
  padding: 20px;
  //   background-color: #f9f9f9;
  //   border-radius: 10px;
  //   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.about-title {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.about-section {
  margin-bottom: 40px;
}

.about-subtitle {
  font-size: 1.8rem;
  color: #007bff;
  margin-bottom: 15px;
}

.about-text {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
}
