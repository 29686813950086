.thanks-page {
  text-align: center;
  padding: 40px 20px;
  font-family: Arial, sans-serif;

  &__top {
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__order {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  &__receipt {
    background-color: #ffd9d9;
    max-width: 400px;
    margin: 0 auto;
    padding: 30px 20px;
    border-radius: 20px;
    text-align: left;

    p {
      margin: 6px 0;
      font-size: 16px;
      line-height: 1.4;
    }

    strong {
      display: block;
      margin-top: 10px;
    }
  }
}
