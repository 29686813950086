.uslugi-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 42px;
  //   background-color: #f4f4f4;
  border-radius: 10px;
  //   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.uslugi-title {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.uslugi-description {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  margin-bottom: 40px;
}

.uslugi-grid {
  margin-top: 82px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.usluga-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: inherit;
  text-decoration: none;
}

.usluga-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.usluga-title {
  font-size: 1.8rem;
  color: #007bff;
  margin-bottom: 15px;
}

.usluga-description {
  font-size: 1rem;
  color: #555;
}

@media (max-width: 768px) {
  .uslugi-container {
    padding: 22px;
  }
  .uslugi-title {
    font-size: 2rem;
  }
  .uslugi-description {
    font-size: 1rem;
  }
  .usluga-card {
    padding: 15px;
  }
  .usluga-title {
    font-size: 1.5rem;
  }
  .usluga-description {
    font-size: 0.9rem;
  }
}
