.timetable__items {
    width: 1400px;
    margin: 0 auto;
    padding: 43px 0;
    list-style: none;
    font-family: 'Montserrat', sans-serif;

    .timetable__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 28px 38px;
        margin: 34px 0;
        background: #FFFFFF;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.13);
        border-radius: 60px;
        transition: 0.3s;
        cursor: pointer;
        position: relative;

        &:hover {
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.273);
        }

        .timetable__item-title {
            margin-bottom: 9px;
            font-weight: 500;
            font-size: 25px;
            line-height: 100%;
        }

        .timetable__item-text {
            font-weight: 300;
            font-size: 18px;
            line-height: 100%;
        }

        .timetable__item-img {
            width: 20px;
            margin-left: 28px;
            transform: translate(-1px, 1px);

            &:hover {
                opacity: 0.7;
            }
        }

        .timetable__notification-icon {
            position: absolute;
            top: 40%;
            left: -10px;
            width: 18px;
            height: 18px;
            border-radius: 50px;
            background: #CC2318;
        }
    }
}

@media(max-width: 1420px) {
    .timetable__items {
        width: 95%;
    }
}

@media(max-width: 650px) {
    .timetable__items {
        width: 95%;

        .timetable__item {
            position: relative;

            .timetable__item-img {
                width: 20px;
                margin-left: 28px;
                transform: none;
                position: absolute;
                top: 45px;
                right: 14px;
            }

            .timetable__notification-icon {
                top: 45%;
                left: 0;
            }
        }
    }
}

@media(max-width: 450px) {
    .timetable__items {
        .timetable__item {
            flex-wrap: wrap;

            .timetable__item-text {
                margin: 8px 0;
            }

            .timetable__item-img {
                top: 65px;
            }
        }
    }
}