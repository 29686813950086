.furni-clean__items {
  display: flex;
  list-style: none;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin: 82px 0;
  li {
    width: 30%;
    display: flex;
    font-size: 18px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px;
    background: #ffffff;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;

    .description {
      margin-top: 20px;
      font-size: 16px;
      color: #7e7e7e;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .furni-clean__items {
    li {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
