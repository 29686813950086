.advantages__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  list-style: none;

  li {
    width: 31%;
    margin-bottom: 22px;
    padding: 16px;
    box-sizing: border-box;
    text-align: center;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    div:first-child {
      font-size: 21px;
      margin-bottom: 10px;
    }
    div:last-child {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.advantages {
  .button {
    width: 322px;
  }
}

@media (max-width: 930px) {
  .advantages__list {
    li {
      width: 100%;
    }
  }
  .advantages .button {
    width: 100%;
  }
}
