.place-order {
  width: 100%;
  height: 330px;
}
.place-order__wrapper {
  margin-top: 320px;
  width: 420px;
}
.place-order__bg {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -435px;
    left: 50%;
    width: 1920px;
    height: 650px;
    background-size: 50%;
    background-repeat: no-repeat;
    background-image: url("../../../../assets/divan2.jpg");
  }
}
.place-order__bg-mob {
  display: none;
}

@media (max-width: 768px) {
  .place-order {
    height: 100%;
    position: relative;
  }
  .place-order__wrapper {
    margin-top: 0;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
  }
  .place-order__bg-mob {
    display: block;
    width: 100%;
    img {
      width: 768px;
    }
  }
  .place-order__bg {
    display: none;
    &::before {
      display: none;
    }
  }
}
