.completion-registration {
  width: 977px;
  background: #FFFFFF;
  box-shadow: 2px 2px 60px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 18px;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.completion-registration__title {
  font-size: 25px;
  line-height: 100%;
  text-align: center;
  padding: 18px;
}

.completion-registration__form {
  label {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  input {
    width: 462px;
    padding: 12px 16px;
    display: flex;
    margin: 12px 0;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #DFDFDF;
    box-shadow: inset 1px 1px 4px rgb(0 0 0 / 25%);
    outline: none;
  }
}

.completion-registration__form-button {
  width: 152px;
  margin: 16px 103px 16px auto;
  padding: 8px 16px;
  display: flex;
  background: #2696D6;
  border-radius: 16px;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 22px;
  line-height: 100%;
  color: #FFFFFF;
  justify-content: center;
  border: none;
  text-decoration: none;
}

.completion-registration__form-text {
  width: 208px;
}

.completion-registration__wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000049;
  z-index: 9999;
}

@media screen and (max-width: 1012px) {
  .completion-registration {
    width: 90%;
    max-width: 750px;
  }

  .completion-registration__form {
    input {
      width: 420px;
    }
  }

  .completion-registration__form-button {
    margin: 16px auto;
  }
}

@media screen and (max-width: 768px) {
  .completion-registration__title {
    font-size: 18px;
  }

  .completion-registration {
    width: 90%;
    max-width: 550px;
  }

  .completion-registration__form {
    input {
      width: 100%;
    }
  }

  .completion-registration__form-text {
    width: 100%;
    font-size: 14px;
  }

  .completion-registration__form-button {
    margin: 16px auto;
  }

  .completion-registration__form-inputs {
    width: 100%;
  }

  label {
    flex-wrap: wrap;
    flex-direction: column;
  }
}