.cross-icon {
  margin-left: auto; /* Размещаем иконку крестика вправо */
}

.rotate {
  transition: transform 0.3s ease; /* Добавляем анимацию вращения */
  transform: rotate(145deg);
}

.active .rotate {
  transform: rotate(
    45deg
  ); /* При активации аккордеона поворачиваем иконку на 45 градусов */
}

.title {
  display: flex;
  justify-content: space-between;
  .title__text {
    font-size: 22px;
    font-weight: 600;
    color: #2696d6;
  }
}
.accordion-item {
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 32px;
  padding: 32px;
  cursor: pointer;
}

.active .title .cross-icon {
  transform: rotate(45deg);
}

.cross-icon {
  transition: transform 0.5s ease;
  background: #2696d6;
  width: 42px;
  height: 42px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
}

.cross-icon:hover {
  background: rgb(38, 181, 233);
}

.rotate {
  transform: rotate(45deg);
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.active .accordion-content {
  max-height: 1000px; /* Устанавливаем максимальную высоту для плавного раскрытия */
}

@media (max-width: 768px) {
  .title .title__text {
    width: 210px;
  }
}
