.our-equipment__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .our-equipment__list-item {
    display: flex;
    align-items: center;
    padding: 22px;
    box-sizing: border-box;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 25%;
    height: 332px;
    transition: 0.3s;
    background: #fff;
    &:hover {
      width: 26%;
      box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.3);
    }
    img {
      width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .our-equipment__list {
    flex-wrap: wrap;
    justify-content: center;
    .our-equipment__list-item {
      width: 65%;
      margin: 22px;
      height: 432px;
      margin: 22px;
      justify-content: center;
      &:hover {
        width: 66%;
      }
      img {
        width: 55%;
      }
    }
  }
}

@media (max-width: 485px) {
  .our-equipment__list {
    .our-equipment__list-item {
      width: 100%;
      height: 312px;
      margin: 22px 0;
      &:hover {
        width: 100%;
      }
      img {
        width: 70%;
      }
    }
  }
}
