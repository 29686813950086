.react-dadata__input {
  width: 100%;
  padding: 7px 12px;
  margin: 4px 0;
  background: #ffffff;
  box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(29, 29, 29, 0.2);
  color: rgba(0, 0, 0, 0.4);
  font-weight: 300;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  line-height: 100%;
  border-radius: 15px;
  box-sizing: border-box;
}

.custom-placeholder {
  color: rgba(0, 0, 0, 0.525);
  padding-left: 4px;
}
