.calendar__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: center;
  }
}
.selected-dates {
  width: 33%;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 22px;
  }
}
// .time-inputs {
//   width: 33%;
// }

.date-item {
  margin-bottom: 8px;
}
