.balance {
  padding: 4px 0;
  width: 100%;
}

.balance__title {
  margin-bottom: 42px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 35px;
  line-height: 100%;
}

.balance__input {
  display: flex;
  margin: 42px auto;
  width: 262px;
  padding: 6px 48px;
  border: none;
  box-sizing: border-box;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 100%;
  background: #ffffff;
  box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.4);
}

.balance__item {
  display: flex;
  width: 262px;
  padding: 22px 12px;
  justify-content: center;
  align-items: center;
  margin: 32px auto;
  box-sizing: border-box;
  border: 0.5px solid #80acf2;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
  opacity: 0.5;
}

.balance__item.active {
  transform: scale(1.1);
  opacity: 1;
}

.balance__item-img {
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: 55px;
  margin: 0 6px;
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  border-radius: 15.8753px;
  box-sizing: border-box;

  img {
    width: 50px;
  }
}

.balance__item-img-container {
  margin: 12px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: inherit;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

.balance__amount-wrapper {
  margin: 0 auto;
  width: 262px;
  text-align: center;
}

.balance__button {
  display: flex;
  margin: 42px auto;
  padding: 6px 18px;
  width: 262px;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-weight: 500;
  font-size: 25px;
  line-height: 100%;
  background: #000000;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;

  &:hover {
    opacity: 0.8;
  }
}

.balance__item-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.balance__button-bonus {
  padding: 6px;
  border-radius: 50px;
  display: flex;
  width: 22px;
  height: 22px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  justify-content: center;
  margin: 0 12px;
}

.balance__bonus-input {
  width: 100%;
  margin: 10px 0;
  padding: 6px;
  background: #ffffff;
  box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(29, 29, 29, 0.2);
  color: rgba(0, 0, 0, 0.4);
  font-weight: 300;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  line-height: 100%;
  border-radius: 15px;
}

.balance__attention-text {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  label {
    flex-wrap: nowrap;
    flex-direction: inherit;
  }
}

@media (max-width: 600px) {
  .balance__amount-wrapper-box {
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 11px;
  }
  .balance__attention-text {
    // display: none;
    font-size: 10px;
  }

  .balance__title {
    font-size: 23px;
    margin-bottom: 32px;
  }
}
