.footer {
  background: #2696d6;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
}

.footer__wrapper {
  display: flex;
  width: 1430px;
  justify-content: space-between;
  margin: 0 auto;
  align-items: start;
  // height: 253px;
  padding: 38px 0;
}

.footer__text-container {
  margin-bottom: 52px;
}

.footer__text {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #ffffff;
  text-decoration: none;
}

.footer__img-icons {
  display: flex;
}

.footer__img-icon {
  margin-right: 26px;
}

.footer__mail {
  margin-bottom: 8px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.footer_number {
  margin-bottom: 22px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.footer__link {
  display: flex;
  padding: 6px 0;
  text-decoration: none;
  letter-spacing: -0.03em;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
}

.footer__download-text {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 18px;
  padding-right: 47px;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 100%;
  color: #ffffff;
}

.footer__download-intro {
  margin-top: 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 137.5%;
  color: #ffffff;
}

@media (max-width: 1500px) {
  .footer__wrapper {
    width: 1300px;
  }
}

@media (max-width: 1380px) {
  .footer__wrapper {
    width: 95%;
  }

  .footer__phone-desktop {
    transform: translateY(40px);
    width: 439px;
  }

  .footer__container {
    width: 230px;
  }

  .footer__text-container {
    margin-bottom: 32px;
  }

  .footer__download-text {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .footer_number {
    font-size: 16px;
  }
}

@media (max-width: 1133px) {
  .footer__phone-desktop {
    display: none;
  }
}

@media (max-width: 690px) {
  .footer__wrapper {
    flex-wrap: wrap;
    justify-content: center;
    padding: 24px 0;
    height: auto;
  }

  .footer__container {
    width: 100%;
    margin: 0;
    padding-bottom: 22px;
  }

  .footer__container-footer__download {
    display: none;
  }
}
