.modal-bg {
  background: #0000005c;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.modal-order {
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 700px;
  z-index: 9999;
  padding: 58px 32px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;

  .title {
    font-style: normal;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    margin-bottom: 18px;
  }

  .intro {
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 100%;
    text-align: center;
  }

  .close {
    position: absolute;
    right: 18px;
    top: 13px;
    font-size: 40px;
    font-weight: 300;
    opacity: 0.8;
    cursor: pointer;
  }
}

.modal-order-payment {
  position: relative;
  transform: translate(0, 0);
  margin: 25vh auto;
  top: 0;
  left: 0;
}

@media screen and (max-width: 768px) {
  .modal-order {
    width: 90%;
    max-width: 550px;

    .title {
      font-size: 18px;
    }

    .intro {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 376px) {
  .modal-order {
    width: 90%;
    max-width: 400px;

    .title {
      font-size: 18px;
    }

    .intro {
      font-size: 12px;
    }
  }
}