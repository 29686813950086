.setting {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 100%;

  @media (max-width: 768px) {
    margin-top: 92px;
  }

  .setting__data {
    display: flex;
    margin: 42px 0;
    width: 1400px;
  }

  .setting__data-item {
    width: 268px;

    div {
      margin-bottom: 18px;
    }
  }

  .setting__data-button {
    padding: 6px 0;
    cursor: pointer;
    font-size: 18px;
    line-height: 100%;
    color: #2696d6;

    &:hover {
      opacity: 0.7;
    }
  }

  .setting__data-add-item {
    padding: 18px 0;

    div {
      padding-bottom: 12px;
    }
  }

  .setting__button-add {
    margin: 6px 0;
    padding: 0px 22px;
    font-size: 24px;
    border: none;
    color: #ffffff;
    background: #2696d6;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  .setting__data-switchs {
    display: flex;
    padding: 38px 0;

    .setting__data-switch {
      margin-right: 192px;
    }

    .setting__data-switch-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
    }
  }
}

.add-item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 220px;

  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.setting__payment-modal {
  position: relative;
}

.payment__modal-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #5555553e;
  z-index: 99999;
}

.payment__modal {
  width: 500px;
  padding: 36px;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  z-index: 99999;

  .payment__modal-header {
    div {
      font-size: 18px;
      line-height: 100%;
      text-align: center;
    }

    .payment__modal-close {
      position: absolute;
      right: 38px;
      top: 38px;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .payment__modal-title {
    margin: 22px 0 12px 0;
  }

  .payment__modal-input {
    width: 89%;
    padding: 12px 22px;
    border: 1px solid #1d1d1d33;
    background: #ffffff;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    font-size: 20px;
    line-height: 100%;
    color: rgba(0, 0, 0, 0.4);
  }

  .payment__modal-inputs-small {
    display: flex;

    .payment__modal-input-small {
      width: 30%;
      padding: 12px 22px;
      border: 1px solid #1d1d1d33;
      background: #ffffff;
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      font-size: 20px;
      line-height: 100%;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .payment__modal-button {
    width: 180px;
    padding: 11px 18px;
    margin: 22px auto;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    background: #2696d6;
    border-radius: 15px;
    font-size: 18px;
    line-height: 100%;
    border: none;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.payment__add-item {
  display: flex;
  align-items: center;

  div {
    padding: 0 !important;
  }
}

.setting__address-modal-input {
  width: 500px;
  padding: 12px 22px;
  border: 1px solid #1d1d1d33;
  background: #ffffff;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-size: 20px;
  box-sizing: border-box;
  line-height: 100%;
  color: rgba(0, 0, 0, 0.4);
}

.setting__address-modal-button {
  padding: 8px 7px;
  width: 136px;
  margin: 2px 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background: #2696d6;
  border-radius: 15px;
  font-size: 18px;
  line-height: 100%;
  border: none;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.css-jsexje-MuiSwitch-thumb {
  border: 1px solid #2696d6 !important;
  width: 16px !important;
  height: 16px !important;
  transform: translate(2px, 2px) !important;
}

.css-1yjjitx-MuiSwitch-track {
  border: 1px solid #2696d6 !important;
  background: none !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  border: 1px solid #2696d6 !important;
  background: none !important;
}

.setting__data-change-modal-inputs {
  width: 400px;

  input {
    width: 100%;
  }
}

.setting__data-change-modal-input {
  width: 216px;
  padding: 8px 16px;
  margin: 12px 0;
  border: 1px solid #1d1d1d33;
  background: #ffffff;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-size: 18px;
  box-sizing: border-box;
  line-height: 100%;
  color: rgba(0, 0, 0, 0.4);
}

.setting__data-change-modal-text {
  color: #2696d6;
}

@media (max-width: 1620px) {
  .setting .setting__data {
    width: 100%;
  }
}

@media (max-width: 1030px) {
  .setting {
    padding: 0 12px;

    .setting__data {
      flex-wrap: wrap;
    }

    .setting__data-change-modal {
      width: 350px;
    }

    .setting__data-item {
      margin-right: 32px;
      padding: 22px 0;
    }

    .setting__data-switchs {
      flex-wrap: wrap;
    }

    .setting__data-switchs .setting__data-switch {
      margin-right: 32px;
      margin-bottom: 22px;
    }
  }
}

@media (max-width: 690px) {
  .setting {
    .setting__data-item {
      margin-bottom: 22px;
    }

    .setting__data-switchs .setting__data-switch {
      margin-right: 0;
    }
  }

  .setting__address-modal-input {
    width: 70%;
  }

  .setting__data-change-modal-inputs {
    width: 90%;

    input {
      width: 100%;
    }
  }
}

@media (max-width: 490px) {
  .setting .setting__data-item {
    width: 100%;
  }

  .setting__address-modal-input {
    width: 100%;
  }

  .payment__modal {
    width: 95%;
    padding: 22px;

    .payment__modal-input {
      width: 70%;
    }

    .payment__modal-title {
      margin: 11px 0 9px 0;
    }

    .payment__modal-header .payment__modal-close {
      top: 20px;
    }
  }
}

@media (max-width: 370px) {
  .setting .setting__data-change-modal {
    width: 100%;
  }
}
