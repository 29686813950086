.sales__items {
  display: flex;
  justify-content: space-between;
  padding: 0;
  li {
    list-style: none;
    margin: 0;
    background: #ffffff;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 32%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .text-container {
      position: absolute;
      bottom: 26px;
      right: 10px;
      box-sizing: border-box;
      padding: 8px;
      background: #ffffff;
      border-radius: 10px;
      text-align: center;
      font-size: 14px;
      width: 272px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .text-container-sale {
        padding: 4px;
        width: 123px;
        margin: 4px 0;
        text-align: center;
        background: #fac91e;
        border-radius: 10px;
        box-sizing: border-box;
        cursor: pointer;
        color: #ffffff;
        // color: #44c767;
      }
    }
  }
}

@media (max-width: 1100px) {
  .sales__items {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    li {
      width: 51%;
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 460px) {
  .sales__items {
    li {
      width: 100%;
    }
  }
}
