.contacts-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.contacts-title {
  color: #333;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

.contact-info {
  margin-bottom: 20px;
}

.contact-info h2 {
  color: #444;
}

.contact-info p {
  margin: 10px 0;
}

.legal-info {
  font-size: 0.9em;
  color: #666;
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 20px;
}
