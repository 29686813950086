.burger-menu {
  position: relative;
  display: none;
}

.menu-icon {
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 20px;
  // transform: translateY(-7px);
  margin-top: -10px;
  position: relative;
}

.bar1,
.bar2,
.bar3 {
  width: 90%;
  height: 2px;
  margin: 6px 0;
  background-color: #000000;
  transition: 0.4s;
  position: relative;
  z-index: 9999;
}

/* Изменяем цвет полосок на белый при прокрутке */
.scrolled .bar1,
.scrolled .bar2,
.scrolled .bar3 {
  background-color: white;
}

.open .bar1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.open .bar2 {
  opacity: 0;
}

.open .bar3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out; /* Добавляем анимацию для плавного появления/исчезания модального окна */
  pointer-events: auto;
}

.menu-items {
  list-style-type: none;
  padding: 0;
}

.menu-items li {
  margin-bottom: 20px;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

.header__wrapper {
  position: sticky;
  top: 0;
  transition: 0.3s;
  z-index: 9999;

  &-bg {
    background: #2696d6;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 99999999;
    position: fixed;
    width: 100%;
  }
}

.nav__menu-mobile {
  display: none;
}

.nav__container-info {
  display: flex;
  align-items: center;
}

.nav__container-info-mobile {
  display: none;
}

.nav__container-time {
  position: relative;
  font-size: 14px;
  opacity: 0.8;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #40b72d;
    animation: blink 1s infinite;
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.nav__container-time-scroll {
  color: #ffffff;
  position: relative;
  font-size: 14px;
  opacity: 0.8;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #40b72d;
    animation: blink 1s infinite;
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.nav__container-order {
  color: #5e92ff;
  position: relative;
  margin-left: 64px;
  margin-right: 18px;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -28px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url("https://airo.ru/images/header/v3/cart.svg");
  }
}

.nav__container-order-scroll {
  color: #fac91e;
  position: relative;
  margin-left: 64px;
  margin-right: 18px;
  text-decoration: none;
}

.nav__container-phone {
  color: #000000;
  margin-left: 46px;
  opacity: 0.8;
}

.nav__container-phone-scroll {
  color: #ffffff;
  margin-left: 46px;
}

.nav__info-name {
  transition: 0.3s;

  &-scroll {
    color: #ffffff;
  }
}

.nav__main {
  width: 1430px;
  margin: 0 auto;
  padding: 18px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}

.nav__info-container {
  width: 410px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo-wrapper {
  position: relative;

  img {
    cursor: pointer;
  }
}

.nav__icon-client {
  margin-right: 6px;
}

.nav__container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    display: flex;
  }
}

.nav__cabinet {
  width: 164px;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: 0.3s;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.236);
  }

  .nav__cabinet-title {
    display: flex;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 100%;
    color: #272727;
  }
}

.nav__cabinet-modal {
  width: 198px;
  position: absolute;
  top: 51px;
  right: 0;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 999;
}

.nav__sign-modal-close {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: none;
  background: #ffffff;
  box-shadow: 1px 1px 6px rgb(0 0 0 / 15%);
  cursor: pointer;
  transition: 0.3s;
}

.nav__sign-modal-close:hover {
  transform: scale(1.03);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.238);
}

.nav__cabinet-modal-link {
  display: flex;
  padding: 12px 0px 12px 22px;
  color: #000000;
  font-weight: 300;
  font-size: 15px;
  line-height: 100%;
  text-decoration: none;
  transition: 0.3s;
  border-radius: 10px;

  &:hover {
    background: #f2f4f6;
    border-radius: 10px;
  }
}

.nav__citie {
  margin-left: 8px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  width: auto;

  &-scroll {
    color: #ffffff;
  }
}

.timetable {
  display: flex;
  width: 612px;
  margin: 54px auto;
  align-items: center;
  background: #ffffff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  text-decoration: none;
  color: #000000;
  box-sizing: border-box;
  transition: 0.3s;
  border: 1px solid #2696d6;

  &:hover {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.236);
  }

  .timetable__title {
    margin-left: 12px;
    margin-right: 23px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
  }

  .timetable__text {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
  }
}

.nav__login {
  display: flex;

  .nav__login-button {
    padding: 8px 16px;
    display: flex;
    margin: 0 8px;
    background: #ffffff;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    transition: 0.3s;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
      box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.249);
    }
  }
}

.nav__sign-modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000049;
}

.nav__sign-modal {
  width: 520px;
  padding: 12px;
  border-radius: 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  .nav__sign-modal-title {
    font-size: 25px;
    line-height: 100%;
    text-align: center;
    padding: 24px 0;
  }

  .nav__sign-modal-title-intro {
    font-weight: 300;
    font-size: 17px;
    line-height: 100%;
    text-align: center;
    padding: 12px 0;
  }

  .nav__sign-modal-button {
    width: 312px;
    margin: 22px auto;
    padding: 8px 16px;
    display: flex;
    background: #2696d6;
    border-radius: 16px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 22px;
    line-height: 100%;
    color: #ffffff;
    justify-content: center;
    border: none;
  }

  .nav__sign-modal-input {
    width: 312px;
    padding: 12px 16px;
    margin: 22px auto;
    display: flex;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #dfdfdf;
    border-radius: 15px;
  }

  .nav__sign-modal-input-yandex {
    color: white;
    background-color: black;
    font-size: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.modal-btn-mobile {
  text-align: center;
}

.button-back {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 28px;
  left: 48px;

  img {
    display: flex;
    margin: 0 auto;
  }
}

@media (max-width: 1500px) {
  .nav__main {
    width: 1300px;
  }
}

@media (max-width: 1380px) {
  .nav__main {
    width: 95%;
  }
  .nav__citie {
    font-size: 14px;
  }
  .nav__container-phone {
    font-size: 14px;
    margin-left: 9px;
  }
  .nav__container-order {
    margin-left: 40px;
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .nav__container {
    width: 320px;
    position: relative;
  }

  .nav__info-container {
    width: 420px;
  }

  .nav__container-info {
    display: none;
  }
  .nav__container-info-mobile {
    display: flex;
    align-items: center;
    // width: 220px;
    justify-content: space-between;
  }

  .nav__container-info-phone {
    position: relative;
    width: 40px;
    height: 40px;
    box-shadow: 0 8px 32px rgba(43, 45, 51, 0.06);
    border-radius: 16px;
    background-size: 24px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 6px;
  }

  .nav__container-info-phone-scroll {
    background: #ffffff;
  }
}

@media (max-width: 690px) {
  .nav__info-name {
    display: none;
  }

  .nav__info-container {
    width: auto;
  }

  .timetable {
    width: 70%;

    img {
      width: 30px;
    }

    .timetable__title {
      margin-left: 8px;
      margin-right: 5px;
      font-size: 13px;
    }

    .timetable__text {
      font-size: 13px;
    }
  }
}

@media (max-width: 596px) {
  .header__wrapper-main {
    background: #f5f5f5;
  }
  .header__wrapper-bg {
    background: #2696d6;
  }
  .nav__main {
    padding: 4px 0;
  }

  .nav__container {
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    a {
      justify-content: center;
    }
  }

  .nav__logo-wrapper {
    width: 100%;
    margin-right: 6px;
  }

  .nav__citie {
    width: 100%;
    padding: 8px 0;
    margin: 0;
    div {
      text-align: center;
    }
  }

  .timetable {
    width: 80%;
  }

  .nav__main {
    flex-wrap: wrap;
    justify-content: center;
  }

  .nav__citie .nav__citie-modal {
    left: -29px;
    top: 40px;
    width: 162px;
  }

  .nav__login {
    width: 100%;
    justify-content: center;
    margin: 12px 0;
  }
}

@media (max-width: 568px) {
  .nav__container-info-mobile {
    width: 65%;
    display: flex;
    justify-content: space-between;
  }
  .nav__login {
    width: auto;
  }
}

@media (max-width: 490px) {
  .timetable {
    width: 90% !important;
    margin: 19px auto;
  }

  .burger-menu {
    display: block;
    margin-top: -9px;
  }
  .nav__main {
    flex-wrap: nowrap;
  }

  .nav__menu-mobile {
    display: block;
    position: absolute;
    left: 0;
    // font-size: 33px;
    // top: -5px;
    z-index: 9999;
  }

  .nav__menu-mobile-burger {
    font-size: 20px;
    left: 0;
    top: 2px;
    position: absolute;
  }

  .nav__menu-mobile-close {
    font-size: 29px;
    left: -4px;
    top: -2px;
    position: absolute;
  }

  .header__modal-menu {
    background: #ffffff;
    width: 65%;
    height: 50%;
    min-height: 320px;
    position: fixed;
    top: 88px;
    left: -300px;
    z-index: 99999;
    border-radius: 20px;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease-in-out;
  }

  .header__modal-menu-open {
    left: 16px;
  }

  .header__modal-menu-close {
    display: inline-flex;
    align-items: center;
    font-size: 38px;
    padding: 8px;
    width: 46px;
    height: 46px;
    box-sizing: border-box;
  }

  .nav__container-info-phone {
    width: 44px;
    height: 44px;
  }

  .header__modal-menu-list {
    padding: 22px 0 0 0;
    margin: 0;
    list-style: none;

    a {
      display: block;
      padding: 8px 0;
      color: #000000;
      text-decoration: none;
      font-size: 20px;
      padding-left: 12px;
    }
  }

  .nav__citie {
    display: none;
  }

  .nav__citie-mob {
    display: flex !important;
    justify-content: center;
    font-size: 13px;
    border-bottom: 1px solid #e6e6e6;
    margin: 8px 0;
  }

  .nav__citie {
    .nav__citie-modal {
      left: 22px;
    }
  }

  .nav__info-container {
    width: 83%;
    display: flex;
    margin: 15px auto;
  }

  .nav__logo-wrapper {
    margin: 0;
  }

  .nav__login {
    width: auto;

    .nav__login-button {
      margin: 0;
    }
  }
}

@media (max-width: 550px) {
  .nav__main {
    justify-content: center;
  }

  .timetable {
    width: 98%;
    margin: 20px auto 56px auto;
  }

  .nav__sign-modal {
    width: 90%;

    .nav__sign-modal-input {
      width: 90%;
    }

    .nav__sign-modal-button {
      width: 90%;
    }
  }

  .nav__info-container {
    width: auto;
  }
  .nav__cabinet {
    width: auto;
    .nav__cabinet-title {
      display: none;
    }
  }
}

@media (max-width: 390px) {
  .nav__sign-modal {
    width: 95%;

    .nav__sign-modal-input {
      width: 100%;
    }

    .nav__sign-modal-button {
      width: 100%;
    }
  }
  .nav__menu-mobile {
    // font-size: 23px;
    top: -2px;
    left: -2px;
  }
  .nav__container {
    width: 75%;
    justify-content: space-between;
  }

  .nav__container-info-mobile {
    width: 75%;
  }
  .nav__container-info-phone {
    width: 36px;
    height: 36px;
  }

  .nav__logo-wrapper {
    img {
      margin-right: 8px;
    }
  }
  .nav__login {
    .nav__login-button {
      padding: 8px 12px;
    }
  }
}
