.completed-services__item-block {
  .block-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .block-main__right {
    justify-content: flex-end;
  }

  .block {
    display: flex;
  }

  .top {
    margin-top: 52px;
  }
  .price {
    margin-left: 52px;
  }

  @media (max-width: 630px) {
    .text {
      padding: 14px 0;
    }
  }
}
.completed-services__items {
  padding: 0 0 12px 0;
  margin: 0;
  list-style: none;
  font-family: "Montserrat", sans-serif;

  .completed-services__item {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 18px 28px;
    margin-bottom: 23px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.13);
    border-radius: 20px;
  }

  .completed-services__text {
    // width: 454px;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    margin: 12px 0;

    span {
      font-weight: 500;
    }
  }

  .completed-services__cancel-button {
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    color: #2696d6;
    margin: 12px 0;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .completed-services__button {
    margin-left: 12px;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    color: #2696d6;
    cursor: pointer;
  }

  .completed-services__container {
    display: flex;
    align-items: center;
  }
}

.completed-services__item-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.completed-services__item-span {
  padding-top: 6px;
  border-top: 1px solid #00000024;
  width: 100%;
  display: flex;
  margin-top: 6px;
  justify-content: space-between;
}

.completed-services__arrow img {
  cursor: pointer;
  transition: transform 0.2s ease;
}

@media (max-width: 630px) {
  .completed-services__items {
    .completed-services__container {
      padding: 12px 0;
    }

    .completed-services__item {
      padding: 4px 14px;
    }

    .completed-services__text {
      width: 100%;
    }
  }

  .completed-services__item-header {
    flex-wrap: wrap;
  }

  .completed-services__item-span {
    flex-wrap: wrap;
  }

  .completed-services__cancel-buttons {
    width: 100%;
  }
}
