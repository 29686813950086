.main__wrapper {
  width: 95%;
  max-width: 1800px;
  padding-top: 112px;
  box-shadow: 1px 1px 4px #00000047;
  margin-left: auto;
  border-top-left-radius: 60px;
  box-sizing: border-box;
  background: #fff;
}

.main__wrapper-promo {
  width: 772px;
  border-radius: 40px;
}

.main__header-info {
  display: flex;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.main__header-info-user {
  display: flex;
  align-items: center;
  img {
    border-radius: 100px;
  }
}

.main__header-info-container {
  margin-left: 28px;

  .main__header-info-name {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    line-height: 100%;
    margin-bottom: 18px;
  }

  .main__header-info-balance-container {
    display: flex;
    align-items: center;
  }

  .main__header-info-balance-text {
    margin-right: 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
  }

  .main__header-info-balance {
    margin-right: 22px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
  }

  .main__header-info-balance-button {
    display: flex;
    text-decoration: none;
    padding: 6px;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    color: #2696d6;
    transition: 0.2s;

    &:hover {
      opacity: 0.6;
    }
  }
}

@media (max-width: 1660px) {
  .main__wrapper-promo {
    width: 452px;
  }
}

@media (max-width: 1112px) {
  .main__wrapper {
    width: 100%;
  }

  .main__header-info {
    padding-left: 22px;
    padding-right: 22px;
  }
}

@media (max-width: 1040px) {
  .main__header-info {
    justify-content: center;
  }

  .main__header-info-user {
    margin-bottom: 22px;
  }

  .main__wrapper-promo {
    width: 362px;
    margin-left: 28px;
  }
}

@media (max-width: 690px) {
  .main__wrapper {
    padding-top: 44px;
  }

  .main__wrapper-promo {
    width: 318px;
    margin-left: 0;
  }

  .main__header-info-user {
    img {
      width: 55px;
      border-radius: 100px;
    }
  }

  .main__header-info {
    padding-left: 43px;
  }

  .main__header-info-container {
    margin-left: 8px;

    .main__header-info-name {
      font-size: 18px;
      margin-bottom: 2px;
    }

    .main__header-info-balance-text {
      font-size: 16px;
      margin-right: 8px;
    }

    .main__header-info-balance {
      margin-right: 5px;
      font-size: 16px;
    }

    .main__header-info-balance-button {
      padding: 5;
      font-size: 17px;
    }
  }
}

@media (max-width: 530px) {
  .main__wrapper {
    // margin-top: 62px;
    width: 100%;
  }

  .main__bg {
    padding-top: 22px;
    background: #f5f5f5;
  }

  .main__header-info {
    padding: 12px 0;
    justify-content: center;
  }

  .main__header-info-user {
    margin-bottom: 32px;
  }
}
