.landing__additional-text-ct {
  text-align: center;

  a {
    text-decoration: none;
  }
}

.landing__additional-list {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 8px 0;
    font-size: 14px;
    color: #000;
    text-decoration: none;
  }
}
