.prices-container {
  margin: 22px auto 82px auto;
  padding: 48px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.06);
  border-radius: 40px;
  width: 1070px;
}

.prices-title__blue {
  padding: 32px 0;
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 100%;
  color: #2696D6;
}

.prices-title__black {
  padding: 32px 0;
  font-weight: 300;
  font-size: 30px;
  line-height: 100%;
  color: #000000;
  font-style: normal;
}

.prices-lists__container {
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
}

.prices-info__container {
  padding: 32px 0;
}

.prices-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 6px 0;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    color: #000000;
  }
}

.prices-list__info {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 32px 0;
  list-style: none;

  li {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 142px;
    height: 80px;
    box-sizing: border-box;
    padding: 12px 0;
    background: #ECF8FF;
    border-radius: 20px;
    transition: 0.3s;

    &:hover {
      transform: scale(1.05);
    }

    div {
      padding: 3px 0;
      font-weight: 300;
      font-size: 18px;
      line-height: 100%;
    }
  }
}

.prices-services {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;

    .prices-services__block {
      display: flex;
      align-items: center;

      font-weight: 300;
      font-size: 18px;
      line-height: 100%;

      img {
        margin-right: 18px;
      }
    }
  }
}

.prices-button {
  display: flex;
  padding: 13px;
  margin: 38px 0 0 0;
  box-sizing: border-box;
  text-decoration: none;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  line-height: 100%;
  color: #FFFFFF;
  background: #2696D6;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  transition: 0.3s;

  &:hover {
    background: #1F7DB0;
  }
}

@media (max-width: 1380px) {
  .prices-container {
    width: 90%;
  }

  .prices-list__info {
    flex-wrap: wrap;

    li {
      margin: 12px 0;
    }
  }
}

@media(max-width: 1030px) {
  .prices-lists__container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .prices-list {
    width: 100%;
    margin: 12px 0;
  }
}

@media (max-width: 768px) {
  .prices-container {
    width: 95%;
    padding: 48px 22px;
  }

  .prices-title__black {
    font-size: 22px;
  }

  .prices-title__blue {
    font-size: 25px;
  }

}

@media (max-width: 440px) {
  .prices-list__info {
    li {
      width: 118px;

      div {
        font-size: 14px;
      }
    }
  }

  .prices-services {

    li {


      .prices-services__block {

        font-size: 14px;

        img {
          width: 90px;
          margin-right: 4px;
        }
      }
    }
  }
}