.slider__wrapper-item {
  display: flex !important;
  align-content: center;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 100%;
    height: 420px;
  }
}

@media (max-width: 1100px) {
  .beforeAfter__wrapper {
    width: 100% !important;
    margin: 28px 0;
  }
  .slider__wrapper-item {
    img {
      width: 100%;
      height: 280px;
    }
  }
}
