.timetable__tab-items {
    padding: 0;
    margin: 0;
    list-style: none;
    font-family: Montserrat, sans-serif;
}

.timetable__tab-item {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    padding: 32px 22px;

    .timetable__tab-item-container {
        display: flex;
    }

    .timetable__tab-button {
        margin-left: 28px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        font-size: 18px;
        line-height: 100%;
        color: #2696D6;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    .timetable__tab-text {
        /* margin-left: 28px; */
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        font-size: 18px;
        line-height: 100%;
    }

    .timetable__tab-text-ct {
        width: 240px;
    }
}

@media(max-width: 1040px) {
    .timetable__tab-item {
        width: 100%;
        padding: 28px 12px;
        box-sizing: border-box;

        .timetable__tab-text {
            margin-left: 0;
        }
    }
}

@media(max-width: 630px) {
    .timetable__tab-text-info {
        text-align: end;
    }

    .timetable__tab-item-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 14px 0px;
    }

    .timetable__tab-item {
        flex-wrap: wrap;
    }

    .timetable__tab-item {
        padding: 28px 2px;

        .timetable__tab-text {
            font-size: 14px;
        }

        .timetable__tab-button {
            margin: 0;
            font-size: 16px;
        }
    }
}