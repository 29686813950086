.uborka-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  //   background-color: #f9f9f9;
  //   border-radius: 8px;
  //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.uborka-title {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.uborka-description {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  margin-bottom: 30px;
}

.uborka-list {
  list-style-type: none;
  padding: 0;
}

.uborka-list-item {
  margin-bottom: 15px;
  text-align: center;
}

.uborka-link {
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.uborka-link:hover {
  color: #0056b3;
}
