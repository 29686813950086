.calculator__data-textarea {
  resize: none;
  width: 462px;
  padding: 12px 16px;
  display: flex;
  margin: 12px 0;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #dfdfdf;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  outline: none;
}

@media screen and (max-width: 768px) {
  .calculator__data-textarea {
    width: 100%;
  }
  .completed-services__text-wrapper {
    display: flex;
    width: 100%;
    margin: 6px 0;
    justify-content: space-between;
  }
}

.calendar__wrapper-order {
  margin-bottom: 42px;
  display: flex;
  flex-wrap: wrap;
  .calendar__wrapper-order-text {
    margin-left: 52px;
    font-size: 22px;
    color: rgb(178, 178, 178);
    // font-weight: 400;
  }
}

@media (max-width: 768px) {
  .calendar__wrapper-order {
    justify-content: center;
    margin-top: 92px;
  }
  .calendar__wrapper-order-text {
    display: none;
  }
}

.rmdp-wrapper {
  border: 2px solid #2696d6;
  box-shadow: none;
}

.completed-services__item-order {
  flex-wrap: wrap;

  .order {
    background: #f2f4f7;
    border-radius: 20px;
    padding: 5px;
    text-align: center;
    min-width: 151px;
    max-height: 28px;
  }
  .text {
    padding: 5px;
  }
  .block {
    display: flex;
    align-self: center;
  }
  .buttons {
    display: flex;
  }
  .button-left {
    margin-left: 14px;
  }
  .text-price {
    width: 100%;
    margin-top: 18px;
    text-align: end;
  }
}
