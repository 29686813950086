.service-list-container {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 22px;
}

.service-list {
  margin: 0;
  padding: 18px 0 0 0;
  list-style: none;
  display: flex;
  width: 50%;
  justify-content: space-between;
  li {
    font-size: 18px;
    font-weight: 300;

    div {
      color: #000;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: #2696d6;
      }
    }

    a {
      text-decoration: none;
    }
  }
}

.service-list__active {
  color: #2696d6 !important;
}

@media (max-width: 968px) {
  .service-list {
    width: 70%;
  }
}

@media (max-width: 568px) {
  .service-list {
    width: 90%;
  }
}

@media (max-width: 468px) {
  .service-list-container {
    width: 100%;
  }
  .service-list {
    width: 90%;
    overflow-x: scroll;
    margin: 0 auto;
    white-space: nowrap;
    li {
      div {
        font-size: 22px;
        padding: 12px;
      }
    }
  }
}
