.cleaning__office {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 520px;
    border-radius: 20px;
    object-fit: cover;
  }
  .button {
    width: 120px;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    background: #ffffff;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    font-size: 16px;
    transition: 0.3s;
    cursor: pointer;
    box-sizing: border-box;
    border: none;
    &:hover {
      box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    }
  }
}

@media (max-width: 930px) {
  .cleaning__office {
    flex-direction: column;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
    .button {
      width: 100%;
      margin: 22px 0;
    }
  }
}
