.benefist__sale {
  display: flex;
  justify-content: space-between;
}

.percent-qty {
  font-size: 110pt;
  font-weight: 600;
}

.percent-block {
  width: 320px;
  text-align: center;
  .title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}

.offer-block {
  width: 320px;
  font-size: 18px;
  font-weight: 300;
}

.offer-description {
  margin-top: 42px;
}

.offer-explanation {
  margin-top: 54px;
  display: inline-flex;
  font-size: 16px !important;
  align-items: center;
}

.benefist__sale-conditions-block {
  padding: 18px;
  list-style: none;
  margin: 0;
  background: #ffffff;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 380px;
  box-sizing: border-box;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .container {
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    width: 90%;

    .title {
      color: #2696d6;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .container-border {
    border-bottom: 1px solid #e0e0e0;
  }
}

@media (max-width: 1100px) {
  .benefist__sale {
    flex-direction: column;
    align-items: center;
  }

  .percent-qty {
    font-size: 80pt;
  }

  .benefist__sale-conditions-block {
    width: 100%;
    margin-top: 30px;
  }
}
