.links-calculator {
  padding: 12px 0 12px 0;
  margin: 0;
  list-style: none;

  @media (max-width: 768px) {
    margin-top: 92px;
  }

  li {
    padding: 6px;
    box-shadow: 2px 2px 8px rgb(0 0 0 / 13%);
    border-radius: 20px;
    width: 300px;
    margin-bottom: 28px;
    margin-left: 16px;

    a {
      display: flex;
      padding: 12px 0px 12px 22px;
      color: #000000;
      font-weight: 300;
      font-size: 15px;
      line-height: 100%;
      text-decoration: none;
    }
  }
}
