.custom-select-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  // box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.2);
  border: 2px solid #2696d6;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  width: 205px;

  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }

  .selected-value {
    font-size: 16px;
    color: #000000;
  }

  .arrow {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #2696d6;
    transition: transform 0.2s ease;

    &.open {
      transform: rotate(180deg);
    }
  }
}
.extra-charge {
  font-size: 12px;
}
.custom-select-dropdown {
  // position: absolute;
  // top: 100%;
  // left: 0;
  // right: 0;
  background-color: #ffffff;
  border: 2px solid #2696d6;
  border-radius: 15px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000000;

  .custom-select-option {
    padding: 10px;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &:hover {
      background-color: #f7f7f7;
    }

    &.disabled-option {
      color: #999999;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.time-inputs {
  position: relative;
  margin-top: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.custom-select-time {
  background-color: #ffffff;
  box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(29, 29, 29, 0.2);
  border-radius: 15px;
  padding: 3px 16px;
  min-width: 57px;
  width: 57px;
  box-sizing: border-box;
  display: block;
  text-align: center;
  margin: 0 5px;
}
