.feedback__wrapper {
  background: #ffffff;
}

.feedback__container-wrapper {
  width: 50%;
}
@media (max-width: 1100px) {
  .feedback__wrapper-content {
    flex-wrap: wrap;
  }
  .feedback__container-wrapper {
    width: 100%;
  }
}

.slider__content {
  width: 100%;
}

.feedback__container-slider {
  width: 100%;
  // width: 492px;
}

.feedback__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.feedback__slider-item {
  height: fit-content;
  width: 86% !important;
  background: #fff;
  border-radius: 16px;
  flex-direction: column;
  padding: 32px;
  margin: 8px auto;
  box-sizing: border-box;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex !important;
}

.feedback__link {
  padding: 8px 16px;
  width: 100%;
  margin: 66px auto 0 auto;
  display: flex;
  text-decoration: none;
  background: #ffffff;
  font-weight: 300;
  color: #000;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: 0.3s;
  cursor: pointer;
  box-sizing: border-box;
  justify-content: center;

  &:hover {
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.3);
  }
}

.feedback__slider-item-title {
  font-size: 18px;
  padding-bottom: 24px;
  border-bottom: 1px solid #f0f0f0;
  white-space: normal;
}

.feedback__slider-item-bottom {
  margin-top: 24px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;

  .title {
    font-size: 14px;
    color: #8c8c8c;
  }
}

.feedback__container-yandex {
  width: 100%;
  margin-top: 32px;
  text-align: center;

  .title {
    font-size: 18px;
  }

  .feedback__link-yandex {
    margin-top: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    img {
      margin-right: 12px;
    }
  }
}

@media (max-width: 1100px) {
  .feedback__container-slider {
    width: 100%;
  }
  .feedback__container-yandex {
    width: 100%;
    margin-top: 42px;
    .title {
      font-size: 22px;
    }

    .feedback__link-yandex {
      width: 100%;
    }
  }
}

@media (max-width: 469px) {
  .feedback__slider-item {
    // width: 85% !important;
    padding: 18px;
  }
}
