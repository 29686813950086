.work-page {
  width: 1300px;
  margin: 0 auto;
  padding-top: 62px;
  box-sizing: border-box;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 42px;
  }

  &__title {
    font-size: 26px;
    font-weight: 500;
  }

  &__income-calculator {
    width: 600px;
    padding: 32px;
    box-sizing: border-box;
    border-radius: 20px;
    background: #d9d9d9;
  }

  &__advantages-title,
  &__conditions-title {
    font-size: 28px;
    color: #ff00d9;
    margin-top: 22px;
  }

  &__advantages {
    width: 50%;
  }

  &__advantages-block {
    height: 512px;
  }

  &__advantage-item {
    margin: 12px 0;
    span {
      color: red;
      font-size: 19px;
      font-weight: 600;
    }
  }

  &__condition-item {
    margin: 12px 0;
  }

  &__benefits {
    width: 666px;
    font-size: 24px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 180.32px;
      height: 415.89px;
      background-image: url("../../assets/benefits-img.svg");
      top: -40px;
      right: 20px;
    }
  }

  &__advantages-title-ancate {
    font-size: 22px;
    margin-bottom: 22px;
  }

  &__advantages-container-blocks {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
    text-decoration: none;
  }

  &__advantages-container-block-1 {
    width: 300px;
    height: 180px;
    border-radius: 20px;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__advantages-container-block-2 {
    width: 300px;
    height: 180px;
    border-radius: 20px;
    background: #404fef;
    font-size: 28px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
  }

  .advantages-2 {
    position: relative;
  }

  .advantages-2::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 415.89px;
    background-image: url("../../assets/benefits-img-2.svg");
    bottom: 0px;
    left: 480px;
  }

  &__benefits-income {
    background: #d9d9d9;
    display: flex;
    justify-content: center;
    border-radius: 22px;
    padding: 6px;
    width: 260px;
    margin: 36px 0;
    span {
      color: red;
      font-size: 24px;
      margin: 0 6px;
      font-weight: 600;
    }
  }
  &__benefits-choice {
    span {
      color: red;
      margin: 0 6px;
      font-weight: 600;
    }
  }
  &__wrapper-advantages {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 52px;
    margin-bottom: 52px;
  }

  &__advantages-phone {
    display: flex;
    align-items: center;
    margin-top: 12px;

    img {
      margin-right: 12px;
    }
  }

  @media (max-width: 1300px) {
    width: 100%;
    padding: 0 20px;

    &__title {
      text-align: center;
    }

    &__wrapper {
      flex-wrap: wrap;
      justify-content: center;
    }

    &__benefits {
      text-align: center;
      margin-top: 23px;
      width: auto;

      &::before {
        display: none;
      }
    }
    &__benefits-income {
      width: auto;
    }
    .advantages-2::before {
      display: none;
    }
    &__wrapper-advantages {
      flex-wrap: wrap;
    }
    &__advantages {
      width: 100%;
      text-align: center;
    }
    &__advantages-container-block-1 {
      width: 48%;
      height: 120px;
      img {
        width: 60px;
      }
    }
    &__advantages-container-block-2 {
      width: 48%;
      height: 120px;
      font-size: 18px;
    }
    &__advantages-phone {
      justify-content: center;
    }
  }
}

.income-calculator {
  &__schedule-list {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    margin-bottom: 22px;
  }

  &__percentage-income {
    position: relative;
    margin-top: 12px;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #000;
      top: -4px;
      right: 0;
    }
  }

  &__percentage-income-title {
    span {
      color: red;
      font-size: 28px;
    }
  }

  &__schedule-item {
    width: 130px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 8px 16px;
    display: flex;
    background: #ffffff;
    border-radius: 20px;
    transition: 0.3s;
    justify-content: center;
    border: 1px solid #fff;

    &--active {
      box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.187);
      transform: scale(1.05);
      border: 1px solid #000000;
    }
  }

  &__fixed-income-amount {
    color: rgb(255, 28, 28);
    font-size: 26px;
  }
  @media (max-width: 1300px) {
    &__fixed-income {
      text-align: center;
    }
    &__percentage-income {
      text-align: center;
    }
  }
  @media (max-width: 600px) {
    &__income-calculator {
      width: 100%;
    }
    &__schedule-item {
      width: 86px;
    }
  }
}
